import React, { useState } from "react";

import icon7 from "../Images/7.svg";
import icon8 from "../Images/8.svg";
import icon9 from "../Images/9.svg";

import firebaseHelper from "../Helpers/Firebase";
import { firestoreConnect } from "react-redux-firebase";

const Contacto = (props) => {
  const [state, setState] = useState({
    nombre: "",
    celular: "",
    email: "",
    mensaje: "",
    estado: 0,
    fecha: new Date(),
  });

  const leerDato = (e) => {
    setState({
      ...state,
      [e.target.id]: e.target.value,
    });
  };

  const enviar = (e) => {
    e.preventDefault();
    const { firestore } = props;

    firebaseHelper.agregar("mensajes", firestore, state, "/envio-exitoso");
  };

  return (
    <section className="container" style={{ marginTop: "50px" }}>
      <p
        className="titulo-sn center"
        style={{ margin: "0", fontWeight: "100" }}
      >
        ¿Tienes algún inmueble, <br className="invisible visible_m visible_c" />
        al cual aún no le explotas <br className="invisible visible_c" /> su
        rentabilidad?
      </p>
      <p className="titulo-1 center" style={{ color: "#BE4F00", margin: "0" }}>
        ¡Contáctanos, nosotros te ayudamos!
      </p>

      <div
        className="row"
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "30px",
        }}
      >
        <div className="col s12 m12 l6">
          <iframe
            title="Mapa CAAV"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3732.212771258054!2d-103.38261552478632!3d20.701582780867064!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428ae10845080e7%3A0x38e40c5ac17f7c1b!2sDesarrollos%20Inmobiliarios%20Jucaba%20SA%20de%20CV!5e0!3m2!1ses!2smx!4v1687201519728!5m2!1ses!2smx"
            style={{ width: "100%", height: "600px", border: "none" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className="col s12 m12 l6 padding-1">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "20px",
            }}
          >
            <div
              style={{
                background: "#008DAB",
                width: "60px",
                height: "60px",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img style={{ width: "27px" }} src={icon7} alt="TELÉFONOS" />
            </div>
            <div style={{ width: "calc(100% - 60px)" }}>
              <p style={{ margin: "0" }}>TELÉFONOS</p>
              <p style={{ margin: "0" }}>3338490461</p>
              <p style={{ margin: "0" }}>3315855194</p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "20px",
              margin: "50px 0",
            }}
          >
            <div
              style={{
                background: "#046DA0",
                width: "60px",
                height: "60px",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{ width: "27px" }}
                src={icon8}
                alt="CORREO ELÉCTRONICO"
              />
            </div>
            <div style={{ width: "calc(100% - 60px)" }}>
              <p style={{ margin: "0" }}>CORREO ELÉCTRONICO</p>
              <p style={{ margin: "0" }}>flores.juan@jucaba.mx </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "20px",
            }}
          >
            <div
              style={{
                background: "#1F2D41",
                width: "60px",
                height: "60px",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img style={{ width: "27px" }} src={icon9} alt="DIRECCIÓN" />
            </div>
            <div style={{ width: "calc(100% - 60px)" }}>
              <p style={{ margin: "0" }}>DIRECCIÓN</p>
              <p style={{ margin: "0" }}>
                Av. Providencia 2571, Colonia Providencia 1ra Seccion,{" "}
                <br className="invisible visible_g" /> CP. 44630 Guadalajara,
                Jalisco.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div style={{ marginTop: "60px" }}>
        <p className="titulo-sn center" style={{ margin: "0" }}>
          Ó déjanos un mensaje
        </p>
        <p
          className="titulo-1 center"
          style={{ margin: "0", fontWeight: "100" }}
        >
          y nos pondremos en contacto lo más pronto posible
        </p>

        <form onSubmit={enviar} className="row">
          <div className="input-field col s12 m6 l4">
            <input
              onChange={leerDato}
              id="nombre"
              type="text"
              className="validate"
              placeholder="TU NOMBRE"
              required
            />
          </div>
          <div className="input-field col s12 m6 l4">
            <input
              onChange={leerDato}
              id="email"
              type="text"
              className="validate"
              placeholder="TU E-MAIL"
              required
            />
          </div>
          <div className="input-field col s12 m12 l4">
            <input
              onChange={leerDato}
              id="celular"
              type="text"
              className="validate"
              placeholder="TU CELULAR"
              required
            />
          </div>
          <div class="input-field col s12">
            <textarea
              onChange={leerDato}
              id="mensaje"
              class="materialize-textarea"
              data-length="120"
              placeholder="MENSAJE"
              required
            ></textarea>
          </div>

          <div className="centrar-flex">
            <button className="boton-1" type="submit">
              ENVIAR
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default firestoreConnect()(Contacto);
