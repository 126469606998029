import React from "react";

const Error = () => {
  return (
    <section
      className="container center"
      style={{
        minHeight: "60vh",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <p className="titulo-error-1">404</p>
      <p className="titulo-error-2">Lo sentimos…</p>
      <p className="texto-error-1">No podemos encontrar ésta página.</p>
      <p className="texto-error-1">
        Puedes regresar al{" "}
        <a style={{ color: "#BE4F00", fontWeight: "bold" }} href="/">
          inicio
        </a>
        , o ver nuestros{" "}
        <a
          style={{ color: "#008DAB", fontWeight: "bold" }}
          href="/proyectos-jucaba"
        >
          proyectos
        </a>
        .
      </p>
    </section>
  );
};

export default Error;
