import React, { Fragment } from "react";

import img7 from "../Images/7.png";
import img7_1 from "../Images/7-1.png";

import video3 from "../Video/nosotros_g.mp4";
import video4 from "../Video/nosotros_m.mp4";
import Contacto from "../Components/Contacto";

const Nosotros = () => {
  return (
    <Fragment>
      {window.screen.width <= 992 ? (
        <div className="imagne-principal">
          <img src={img7_1} alt="Somos JUCABA" style={{ width: "100%" }} />
          <div>
            <p>
              <span className="titulo-3">SOMOS</span>
              <br />
              <span className="titulo-4">JUCABA</span>
              <br />
              <span>Desarrollos inmobiliarios</span>
            </p>
          </div>
        </div>
      ) : (
        <div className="imagne-principal">
          <img src={img7} alt="Somos JUCABA" style={{ width: "100%" }} />
          <div>
            <p>
              <span className="titulo-3">SOMOS</span> <br />
              <span className="titulo-4">JUCABA</span> <br />
              <span>Desarrollos inmobiliarios</span>
            </p>
          </div>
        </div>
      )}

      <section className="container center">
        <p className="titulo-1">En Desarrollos Inmobiliarios JUCABA</p>
        <p>
          Administramos parques industriales, oficinas, locales comerciales y{" "}
          <br className="invisible visible_g visible_mg visible_m" />
          Modelos Coliving de estancias cortas con la garantía de generar un
          alto <br className="invisible visible_g visible_mg visible_m" />{" "}
          rendimiento y plusvalía de las propiedades para nuestros
          inversionistas.
        </p>

        <div className="contenedor-1">
          <p style={{ margin: "0", color: "#ffffff" }} className="titulo-sn">
            Ofreciendo el mejor servicio desde el
          </p>
          <p style={{ margin: "0" }} className="titulo-4">
            2005
          </p>
        </div>

        {window.screen.width <= 992 ? (
          <video class="responsive-video" controls={true}>
            <source src={video4} type="video/mp4" />
          </video>
        ) : (
          <video class="responsive-video" controls={true}>
            <source src={video3} type="video/mp4" />
          </video>
        )}
      </section>

      <section className="container" style={{ marginTop: "50px" }}>
        <p className="titulo-sn" style={{ alignItems: "flex-start" }}>
          Nuestra experiencia
        </p>
        <div className="row">
          <div className="col s12 m6">
            <p
              style={{
                margin: "0",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <b>Metros urbanizados</b>
              <b>103,000 m2</b>
            </p>
            <div class="progress">
              <div
                class="determinate"
                style={{ width: "100%", backgroundColor: "#008DAB" }}
              ></div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col s12 m6">
            <p
              style={{
                margin: "0",
                display: "flex",
                justifyContent: "space-between",
                width: "60%",
              }}
            >
              <b>Remodelados</b>
              <b>3,000 m2</b>
            </p>
            <div class="progress">
              <div
                class="determinate"
                style={{ width: "60%", backgroundColor: "#046DA0" }}
              ></div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col s12 m6">
            <p
              style={{
                margin: "0",
                display: "flex",
                justifyContent: "space-between",
                width: "80%",
              }}
            >
              <b>Construidos</b>
              <b>15,000 m2</b>
            </p>
            <div class="progress">
              <div
                class="determinate"
                style={{ width: "80%", backgroundColor: "#1F2D41" }}
              ></div>
            </div>
          </div>
        </div>
      </section>

      <Contacto />
    </Fragment>
  );
};

export default Nosotros;
