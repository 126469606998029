import React from "react";

const Terminos = () => {
  return (
    <section className="container center">
      <p className="titulo-1">AVISO DE PRIVACIDAD</p>
      <p>
        A los Usuarios (como se definen posteriormente), les informamos que el
        siguiente Aviso de Privacidad, les es aplicable por el simple uso o
        acceso a cualquiera de las páginas, aplicaciones web y móviles,
        softwares y, aplicaciones en general, que integran el Portal de
        jucaba.mx (en adelante y, conjunta e indistintamente, el “Portal”), por
        lo que entenderemos que lo acepta y acuerda en obligarse en su
        cumplimiento. En caso de que no esté de acuerdo con el Aviso de
        Privacidad y/o con los Términos y Condiciones a su disposición, deberá
        abstenerse de acceder o utilizar el Portal.
      </p>
      <p>
        El Usuario, entendido como aquella persona que realiza el uso o accede,
        mediante equipo de cómputo y/o cualquier equipo de comunicación o
        dispositivo, al Portal (en adelante el “Usuario”), acepta plena y sin
        reserva todas y cada una de las disposiciones incluidas en el presente
        Aviso de Privacidad.
      </p>
      <p>
        <b>RESPONSABLE DEL TRATAMIENTO DE SUS DATOS PERSONALES.</b>
        <br />
        Para “Desarrollos Inmobiliarios JUCABA” (en adelante la “Empresa”) la
        seguridad de los Usuarios es nuestra prioridad, por lo que protegemos
        sus datos personales mediante el uso, aplicación y mantenimiento de
        altas medidas de seguridad técnicas, físicas y administrativas.
      </p>
      <p>
        Como Usuario, usted tiene la oportunidad de escoger entre una amplia
        gama de servicios a través de nuestro Portal, sabiendo que sus datos
        personales estarán protegidos y serán tratados de manera confidencial.
        Les informamos que el RESPONSABLE de recabar y dar tratamiento y/o
        utilizar los datos personales que el Usuario proporcione a través del
        Portal, es la Empresa, así como sus subsidiarias, asociadas, sociedades
        controladoras y afiliadas.
      </p>
      <p>
        <b>DOMICILIO DEL RESPONSABLE.</b>
        <br />
        Para efectos del presente aviso de privacidad, la Empresa señala,
        individualmente, como su domicilio, el ubicado en Av. Providencia 2571,
        Col. Providencia.
      </p>
      <p>
        <b>DATOS PERSONALES QUE PUEDEN SER RECOLECTADOS.</b>
        <br />
        Los datos personales que la Empresa puede recopilar del Usuario al
        utilizar el Portal y/o contratar nuestros servicios, son los siguientes:{" "}
        <br />
        Nombre, teléfono y correo <br />
        Acceso desde redes sociales <br />
        Características y ubicación de su inmueble <br />
        Datos personales para investigación de buró crediticio.
      </p>
      <p>
        <b>FINALIDADES DEL TRATAMIENTO DE SUS DATOS PERSONALES.</b>
        <br />
        Los datos personales que la Empresa recabe serán utilizados para atender
        las siguientes finalidades: <br />
        Identificación de cliente y garantizar una atención personalizada, Para
        integrar expedientes, bases de datos y sistemas necesarios para llevar a
        cabo las operaciones y servicios correspondientes; (ii) Para llevar a
        cabo análisis internos; De manera adicional, se podrán utilizar sus
        datos personales para las siguientes finalidades secundarias: (i)
        Mercadotecnia, publicidad y prospección comercial; (ii) Ofrecerle, en su
        caso, otros servicios propios de la Empresa o de cualquiera de sus
        afiliadas, subsidiarias, sociedades controladoras, asociadas,
        comisionistas o sociedades; (iii) Remitirle promociones de otros bienes
        y servicios; (iv) Para realizar análisis estadísticos, de generación de
        modelos de información y/o perfiles de comportamiento actual y
        predictivo y, (v) Para participar en encuestas, sorteos y promociones.
      </p>
      <p>
        <b>OPCIONES Y MEDIOS PARA LIMITAR EL USO O DIVULGACIÓN DE LOS DATOS.</b>
        <br />
        La Empresa tiene implementadas medidas de seguridad administrativas,
        técnicas y físicas para proteger sus datos personales, mismas que
        igualmente exigimos sean cumplidas por los proveedores de servicios que
        contratamos. Usted podrá limitar el uso o divulgación de sus datos
        personales enviando un correo electrónico a flores.juan@floba.mx
        indicándonos en el cuerpo del correo su nombre completo y que dato desea
        que no sea divulgado, de proceder su solicitud, se le registrará en el
        listado de exclusión.
      </p>
      <p>
        <b>
          MEDIOS PARA EJERCER LOS DERECHOS DE ACCESO, RECTIFICACIÓN, CANCELACIÓN
          U OPOSICIÓN (DERECHOS ARCO).{" "}
        </b>
        <br />
        Puede enviar un correo electrónico a flores.juan@floba.mx, en cualquier
        momento, para ejercer sus Derechos de Acceso, Rectificación, Cancelación
        u Oposición (“Derechos ARCO”). Para ejercer los Derechos ARCO, usted (o
        su representante legal), deberá presentar la solicitud, identificándose
        con la siguiente documentación:
        <br />
        Nombre del usuario o titular.
        <br />
        Domicilio del usuario o titular u otro medio para comunicarle la
        respuesta a su solicitud.
        <br />
        Documentos que acrediten su identidad (IFE/INE o pasaporte) y, en su
        caso, los documentos que acrediten la representación legal del
        solicitante.
        <br />
        Una descripción de la información / los datos sobre los que está
        tratando de ejercer sus derechos ARCO y los derechos que le gustaría
        ejercer.
        <br />
        Si uno o más de los documentos mencionados anteriormente no están
        incluidos, y/o los documentos tienen errores u omisiones, la Empresa le
        notificará dentro de los 3 días hábiles posteriores a la recepción de la
        solicitud y le pedirá los documentos faltantes y/o las correcciones
        pertinentes; tendrá 5 días hábiles a partir de esa notificación para
        proporcionar la información actualizada, de lo contrario, la solicitud
        se entenderá como no presentada.
      </p>
      <p>
        <b>TRANSFERENCIA DE DATOS PERSONALES.</b>
        <br />
        La Empresa podrá divulgar sus datos personales a aquellos terceros que,
        en virtud de los servicios y productos ofrecidos, necesiten conocerlos
        para cumplir cabalmente con los mismos. Asimismo, la Empresa puede
        divulgar su información a las autoridades competentes en términos de la
        legislación aplicable; cualquier transferencia de sus datos personales
        sin consentimiento se realizará de acuerdo con el Artículo 37 de la
        LFPDPPP.
      </p>
      <p>
        <b>WEB BEACONS. </b>
        <br />
        La Empresa, podrá o no, utilizar tecnologías de seguimiento tales como
        web beacons, similares a las cookies, para recabar datos sobre sus
        visitas en el Portal; éstas son pequeñas imágenes electrónicas
        incrustadas en el contenido web o mensajes de correo electrónico, las
        cuales no se encuentran normalmente visibles para los Usuarios y que nos
        permiten generar contenidos casi personalizados para ofrecerle una mejor
        experiencia cuando utilice nuestro Portal.
        <br />
        En caso de no estar de acuerdo con cualquiera de las condiciones aquí
        establecidas, el Usuario siempre podrá cambiar la configuración de su
        navegador.
      </p>
      <p>
        <b>MODIFICACIONES AL AVISO DE PRIVACIDAD.</b>
        <br />
        El presente aviso de privacidad puede sufrir modificaciones, cambios o
        actualizaciones derivadas, entre otras cosas, por nuevos requerimientos
        legales; necesidades propias de la Empresa, por los productos o
        servicios que ofrecemos; por nuestras prácticas de privacidad; por
        cambios en nuestro modelo de negocio, o por otras causas. Cualquier
        modificación al presente aviso de privacidad le será notificada a través
        de cualquiera de los siguientes medios: un comunicado por escrito
        enviado a su domicilio; por el correo electrónico que señale; un mensaje
        a su teléfono móvil; un mensaje dado a conocer a través del Portal o de
        cualquier medio electrónico que utilice para celebrar operaciones; o
        bien, en periódicos de amplia circulación el domicilio social de la
        Empresa.
      </p>

      <p className="titulo-1">TÉRMINOS Y CONDICIONES</p>
      <p>
        A los Usuarios, les informamos que los siguientes Términos y Condiciones
        les son aplicables por el simple uso o acceso a cualquiera de las
        páginas, aplicaciones web y móviles, softwares y, aplicaciones en
        general, que integran el Portal de jucaba.mx (en adelante y, conjunta e
        indistintamente, el “Portal”), por lo que entenderemos que los acepta y
        acuerda en obligarse a su cumplimiento. En el caso de que no esté de
        acuerdo con los Términos y Condiciones deberá abstenerse de acceder o
        utilizar el Portal.
      </p>
      <p>
        “Desarrollos inmobiliarios JUCABA” Y/o sus subsidiarias, controladoras,
        partes relacionadas y afiliadas (en adelante y, conjunta e
        indistintamente, la “Empresa”) se reservan el derecho de modificar
        discrecionalmente el contenido del Portal en cualquier momento, sin
        necesidad de previo aviso.
      </p>
      <p>
        El Usuario, entendido como aquella persona que realiza el uso o accede
        al Portal, mediante equipo de cómputo y/o cualquier equipo de
        comunicación o dispositivo (en adelante el “Usuario”), conviene en no
        utilizar dispositivos, software, o cualquier otro medio tendiente a
        interferir tanto en las actividades y/u operaciones del Portal, en las
        bases de datos y/o información que se contenga en el mismo.
      </p>
      <p>
        <b>1. USO Y RESTRICCIONES.</b>
        <br />
        El acceso o utilización del Portal expresa la adhesión plena y sin
        reservas del Usuario a los presentes Términos y Condiciones. A través
        del Portal, el Usuario se servirá, contratará y/o utilizará diversos
        servicios y contenidos (los “Servicios y Contenidos”), puestos a
        disposición por la Empresa. La Empresa tendrá el derecho a negar,
        restringir o condicionar al Usuario el acceso al Portal, total o
        parcialmente, a su entera discreción, así como a modificar los Servicios
        y Contenidos del Portal en cualquier momento y sin necesidad de previo
        aviso.
      </p>
      <p>
        El Usuario reconoce que no todos los Servicios y Contenidos están
        disponibles en todas las áreas geográficas y que algunos Servicios y
        Contenidos pueden ser utilizados solamente con posterioridad a su
        contratación, activación o registro previo por el Usuario y/o mediante
        el pago de estos. La Empresa no garantiza la disponibilidad y
        continuidad de la operación del Portal y de los Servicios y Contenidos,
        ni la utilidad del Portal o los Servicios y Contenidos en relación con
        cualquier actividad específica, independientemente del medio de acceso
        que utilice el Usuario. La Empresa no será responsable por daño o
        pérdida alguna de cualquier naturaleza que pueda ser causado debido a la
        falta de disponibilidad o continuidad de operación del Portal y/o de los
        Servicios y Contenidos.
      </p>
      <p>
        El aprovechamiento de los Servicios y Contenidos en el Portal, es
        exclusiva responsabilidad del Usuario, quien en todo caso deberá
        servirse de ellos acorde a las funcionalidades permitidas en el propio
        Portal y a los usos autorizados en los presentes Términos y Condiciones,
        por lo que el Usuario se obliga a utilizarlos de tal modo que no
        contravengan las buenas costumbres, los derechos de terceros, las normas
        de uso y convivencia en Internet, las leyes de los Estados Unidos
        Mexicanos y, la legislación vigente en el país en que el Usuario se
        encuentre al acceder al Portal y usar los Servicios y Contenidos. El
        Portal es para el uso individual del Usuario por lo que no podrá
        comercializar de manera alguna los Servicios y Contenidos.
      </p>
      <p>
        <b>2. RESTRICCIONES. </b>
        <br />
        El Usuario no tiene el derecho de colocar hiperligas dentro del Portal,
        a utilizar las ligas del Portal, ni el derecho de colocar o utilizar los
        Servicios y Contenidos en sitios o páginas propias o de terceros sin
        autorización previa y por escrito de la Empresa. El Usuario no podrá
        impedir a cualquier otro Usuario el uso del Portal ni de los Servicios y
        Contenidos.
      </p>
      <p>
        <b>3. PROPIEDAD INTELECTUAL.</b>
        <br />
        Los derechos de propiedad intelectual, propiedad industrial respecto de
        los Servicios y Contenidos, los signos distintivos y dominios de las
        Páginas o del Portal, así como los derechos de uso y explotación de
        estos, incluyendo de manera enunciativa más no limitativa, su
        divulgación, publicación, reproducción, distribución y transformación,
        son propiedad exclusiva de la Empresa. El Usuario no adquiere ningún
        derecho de propiedad intelectual y/o industrial por el simple uso o
        acceso de los Servicios y Contenidos del Portal y, en ningún momento,
        dicho uso será considerado como una autorización o licencia para
        utilizar los Servicios y Contenidos con fines distintos a los que se
        contemplan en los presentes Términos y Condiciones.
      </p>
      <p>
        <b>4. PROPIEDAD INTELECTUAL DE TERCEROS. </b>
        <br />
        El Usuario acuerda que las disposiciones que se establecen en el
        artículo 3 anterior respecto de la titularidad de los derechos de la
        Empresa, también son aplicables a los derechos de terceros respecto de
        los Servicios y Contenidos de las Páginas, dominios o información
        presentada o vinculada al Portal.
      </p>
      <p>
        <b>5. CALIDAD DE LOS SERVICIOS Y CONTENIDOS Y SUS GARANTÍAS.</b>
        <br />
        Ni la Empresa, ni sus proveedores o socios comerciales, serán
        responsables de cualquier daño o perjuicio que sufra el Usuario a
        consecuencia de inexactitudes, consultas realizadas, asesorías, errores
        tipográficos y cambios o mejoras que se realicen periódicamente a los
        Servicios y Contenidos. Las recomendaciones y consejos obtenidos a
        través del Portal son de naturaleza general, por lo que no deben tomarse
        en cuenta en la adopción de decisiones personales ni profesionales. Para
        ello, se debe consultar a un profesional apropiado que pueda asesorar al
        Usuario de acuerdo con sus necesidades específicas.
      </p>
      <p>
        La Empresa ofrece los Servicios y Contenidos con un nivel de competencia
        y diligencia razonable desde un punto de vista comercial, sin embargo,
        no ofrece ningún tipo de garantía en relación con estos. El Portal es
        proporcionado por la Empresa “tal como está” y “según disponibilidad”.
        La Empresa no manifiesta ninguna representación o garantía de ningún
        tipo, expresa o implícita, en relación con la operación del Portal,
        información, contenido, materiales o productos incluidos. El Usuario
        acepta expresamente que el uso del Portal es bajo su propio riesgo. La
        Empresa se reserva el derecho a remover o eliminar cualquier información
        del Portal, en cualquier momento, a su entera discreción. Ni la Empresa,
        ni sus proveedores o distribuidores ofrecen garantías específicas sobre
        los Servicios y Contenidos; la Empresa excluye todas las garantías en la
        medida que las leyes vigentes lo permitan.
      </p>
      <p>
        De conformidad con los Términos y Condiciones, la Empresa no asume ni
        asumirá ningún tipo de responsabilidad frente a ninguna persona,
        derivada o que pudiera derivarse por los Servicios y Contenidos,
        navegación en el Portal, consultas, aclaraciones y/o cualquier otra
        clase de respuesta otorgada por parte de la Empresa por cualquier medio
        de comunicación.
      </p>
      <p>
        El Usuario se obliga a dejar en paz y a salvo a la Empresa, a sus
        accionistas, subsidiarias, afiliadas, funcionarios, directores,
        empleados, asesores, apoderados, representantes y/o cualquier persona
        relacionada con esta, de cualquier responsabilidad que pudiera imputarse
        en virtud y/o en relación con el Portal, la prestación de los Servicios
        y Contenidos o cualquier otro derivado de los presentes Términos y
        Condiciones.
      </p>
      <p>
        El Usuario entiende y acepta que la Empresa se encontrará limitada por
        responsabilidad de cualquier tipo, en todos los casos, al monto pagado
        como contraprestación por los Servicios y Contenidos.
      </p>
      <p>
        <b>5.1 DEVOLUCIONES Y CANCELACIONES. </b>
        <br />
        Para seguridad de los Usuarios, en cualquier momento podrán cancelar los
        Servicios y Contenidos que adquieran en el Portal, a su entera
        discreción y sin responsabilidad alguna. El Usuario reconoce que los
        cargos que se realicen a tarjetas de débito o crédito, con motivo de los
        Servicios y Contenidos, no serán objeto de devolución y, que al realizar
        dichos pagos se sujeta a los Términos y Condiciones de los proveedores
        de servicios relacionados con dichos pagos.
      </p>
      <p>
        <b>5.2 SUSCRIPCIONES.</b>
        <br />
        Es posible que para el uso y/o contratación de algunos o todos los
        Servicios y Contenidos, sea necesario el registro del Usuario y que este
        cuente con un método de pago automático y válido, asociado a dicho
        registro, método que podrá ser modificado o cancelado, a discreción del
        Usuario. En su caso, el Usuario se sujetará a los Términos y Condiciones
        de la plataforma de pagos.
      </p>
      <p>
        <b>5.3 ENVÍOS.</b>
        <br />
        La Empresa no será responsable de ninguna demora y/o incumplimiento
        respecto de los envíos realizados ni mediante terceros ajenos a la
        Empresa, el Usuario acepta los riesgos adjuntos y se sujeta a los
        Términos y Condiciones del proveedor de servicios encargado del envío.
      </p>
      <p>
        <b>6. BIENES Y SERVICIOS DE TERCEROS ENLAZADOS. </b>
        <br />
        El hecho de que se ofrezca información en el Portal o en otros sitios
        ligados o vinculados, no implica la recomendación, garantía, patrocinio
        o aprobación por parte de la Empresa respecto de dicha información,
        bienes y/o servicios. La disponibilidad de bienes y/o servicios
        ofertados por terceros o por sitios ligados o vinculados, no es
        responsabilidad de la Empresa. En virtud de lo anterior, la Empresa no
        será responsable ante cualquier autoridad de cualquier naturaleza, por
        cualquier asunto relacionado con la venta, consumo, distribución,
        entrega, disponibilidad o prestación con respecto de cualquiera de los
        bienes y/o servicios ofertados por terceros o por sitios ligados o
        vinculados a través del Portal.
      </p>
      <p>
        Respecto de los Servicios y Contenidos que prestan terceros dentro o
        mediante enlaces al Portal (tales como ligas, banners y botones), la
        Empresa se limita exclusivamente, para conveniencia del Usuario, a: (i)
        informar al Usuario sobre los mismos y, (ii) a proporcionar un medio
        para poner en contacto al Usuario con proveedores o vendedores. Los
        productos y/o servicios que se comercializan dentro del Portal y/o en
        los sitios de terceros enlazados son suministrados por comerciantes
        independientes y no se entenderá en ningún caso que son responsabilidad
        de la Empresa. No existe ningún tipo de relación laboral, asociación o
        sociedad, entre la Empresa y dichos terceros. Toda asesoría, consejo,
        declaración, información y contenido de las páginas de terceros
        enlazadas o dentro del Portal representan las opiniones y juicios de
        dicho tercero, consecuentemente, la Empresa no será responsable de
        ningún daño o perjuicio que sufra el Usuario a consecuencia de estos.
      </p>
      <p>
        <b>7. CONFIDENCIALIDAD. </b>
        <br />
        La Empresa se obliga a mantener confidencial la información que reciba
        del Usuario que tenga dicho carácter conforme a las disposiciones
        legales aplicables en los Estados Unidos Mexicanos; la Empresa no asume
        ninguna obligación de mantener confidencial cualquier otra información
        que el Usuario le proporcione. <br />
        8. USO DE LA INFORMACIÓN NO CONFIDENCIAL. Mediante el uso del Portal, el
        Usuario autoriza a la Empresa, de manera enunciativa más no limitativa,
        a utilizar, publicar, reproducir, divulgar, comunicar públicamente y
        transmitir la información no confidencial, en términos de lo establecido
        en la Ley Federal de Protección de Datos Personales en Posesión de
        Particulares, en la Ley Federal de los Derechos de Autor, en la Ley
        Federal de Protección al Consumidor y en cualquiera otra aplicable en la
        legislación mexicana.
      </p>
      <p>
        <b>9. COOKIES.</b>
        <br />
        El Usuario que tenga acceso al Portal, conviene recibir archivos que les
        transmitan los servidores de la Empresa. Una “Cookie” es un archivo de
        datos que se almacena en el disco duro de la computadora del Usuario
        cuando éste accede al Portal. Dichos archivos pueden contener
        información tal como la identificación proporcionada por el Usuario o
        información para rastrear las páginas que el Usuario ha visitado. Una
        Cookie no puede leer los datos o información del disco duro del Usuario
        ni leer las Cookies creadas por otros sitios o páginas. Generalmente,
        las Cookies son aceptadas automáticamente, el Usuario puede cambiar la
        configuración de su navegador en cualquier momento. En caso de que el
        Usuario decida rechazar las Cookies, es posible que ciertas secciones
        del Portal no tengan su funcionamiento óptimo o incluso no funcionen en
        absoluto.
      </p>
      <p>
        <b>10. AVISO DE PRIVACIDAD DE DATOS PERSONALES. </b>
        <br />
        Toda la información que la Empresa recabe del Usuario es tratada con
        absoluta confidencialidad conforme las disposiciones legales aplicables
        en la legislación mexicana. Para conocer más sobre la protección de sus
        datos personales por favor consulte nuestro Aviso de Privacidad.
      </p>
      <p>
        <b>11. CLAVES DE ACCESO.</b>
        <br />
        En todo momento, el Usuario es el responsable único y final de mantener
        en secreto las claves de acceso que pudiera tener y con las cuales
        tuviera acceso a ciertos Servicios y Contenidos del Portal.
      </p>
      <p>
        <b>11.1 CUENTAS. </b>
        <br />
        El Usuario, al crear una cuenta en el Portal, declara, bajo protesta de
        decir verdad, que tiene como mínimo 18 años de edad o la mayoría de edad
        legal en su jurisdicción; el Usuario reconoce que es su responsabilidad
        cualquier actividad que se desarrolle con dicha cuenta o a través de
        ella y reconoce que tiene conocimiento y acepta las condiciones
        establecidas en los presentes Términos y Condiciones y en el Aviso de
        Privacidad.
      </p>
      <p>
        <b>12. MODIFICACIONES. </b>
        <br />
        La Empresa tendrá el derecho de modificar, en cualquier momento, los
        Términos y Condiciones, sin previo aviso y/o consentimiento del Usuario.
        En consecuencia, el Usuario debe leer atentamente los Términos y
        Condiciones cada vez que pretenda utilizar el Portal. Ciertos Servicios
        y Contenidos ofrecidos a los Usuarios en y/o a través del Portal están
        sujetos a condiciones particulares propias que sustituyen, completan y/o
        modifican los presentes Términos y Condiciones. Consiguientemente, el
        Usuario también debe leer atentamente las correspondientes condiciones
        particulares antes de acceder a cualquiera de los Servicios y
        Contenidos.
      </p>
      <p>
        <b>13. LEYES APLICABLES Y JURISDICCIÓN.</b>
        <br />
        Para la interpretación, cumplimiento y ejecución de los presentes
        Términos y Condiciones, el Usuario está de acuerdo en que serán
        aplicables las leyes Federales de los Estados Unidos Mexicanos y
        competentes los tribunales de Guadalajara Jalisco, renunciando
        expresamente a cualquier otro fuero o jurisdicción que pudiera
        corresponderles debido a sus domicilios presentes o futuros o por
        cualquier otra causa.
      </p>

      <p>
        <b>14. CONTACTO.</b>
        <br />
        El Usuario puede contactar, en todo momento, al personal de la Empresa
        para cualquier aclaración, comentario, duda y/o sugerencia relacionada
        con los Servicios y Contenidos, con el Portal y/o con los presentes
        Términos y Condiciones en jucaba.mx
      </p>
    </section>
  );
};

export default Terminos;
