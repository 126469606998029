import React, { Fragment, useEffect } from "react";
import M from "materialize-css";

import logo from "../Images/logo.png";

const Header = () => {
  useEffect(() => {
    var elems = document.querySelectorAll(".sidenav");
    M.Sidenav.init(elems);
  }, []);

  return (
    <Fragment>
      <nav className="header-propio">
        <div className="nav-wrapper">
          <a href="/" className="brand-logo">
            <img src={logo} alt="JUCABA" style={{ width: "300px" }} />
          </a>
          <a href="#!" data-target="mobile-demo" className="sidenav-trigger">
            <i style={{ color: "#1F2D41" }} className="material-icons">
              menu
            </i>
          </a>
          <ul className="right hide-on-med-and-down">
            <li>
              <a href="/">Inicio</a>
            </li>
            <li>
              <a href="/somos-jucaba">Somos</a>
            </li>
            <li>
              <a href="/proyectos-jucaba">Proyectos</a>
            </li>
            <li>
              <a href="/servicios-jucaba">Servicios</a>
            </li>
            <li>
              <a href="/contactanos">Contacto</a>
            </li>
          </ul>
        </div>
      </nav>

      <ul className="sidenav" id="mobile-demo">
        <li>
          <a href="/">Inicio</a>
        </li>
        <li>
          <a href="/somos-jucaba">Somos</a>
        </li>
        <li>
          <a href="/proyectos-jucaba">Proyectos</a>
        </li>
        <li>
          <a href="/servicios-jucaba">Servicios</a>
        </li>
        <li>
          <a href="/contactanos">Contacto</a>
        </li>
      </ul>
    </Fragment>
  );
};

export default Header;
