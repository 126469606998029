import React from "react";

import logo_g from "../Images/logo_g.png";

// galeria
import galeria1 from "../Images/galeria/1.png";
import galeria2 from "../Images/galeria/2.png";
import galeria3 from "../Images/galeria/3.png";
import galeria4 from "../Images/galeria/4.png";
import galeria5 from "../Images/galeria/5.png";
import galeria6 from "../Images/galeria/6.png";

const Footer = () => {
  const galeria = [
    {
      imagen: galeria1,
      titulo: "Imagen galeria JUCABA 1",
    },
    {
      imagen: galeria2,
      titulo: "Imagen galeria JUCABA 2",
    },
    {
      imagen: galeria3,
      titulo: "Imagen galeria JUCABA 3",
    },
    {
      imagen: galeria4,
      titulo: "Imagen galeria JUCABA 4",
    },
    {
      imagen: galeria5,
      titulo: "Imagen galeria JUCABA 5",
    },
    {
      imagen: galeria6,
      titulo: "Imagen galeria JUCABA 6",
    },
  ];

  return (
    <footer className="page-footer" style={{ backgroundColor: "#E4E4E4" }}>
      <div className="container center-movil">
        <div className="row">
          <div className="col s12 m6 l3 offset-m3">
            <img src={logo_g} alt="JUCABA" style={{ width: "100%" }} />
          </div>
          <div className="col s12 m6 l3 offset-m3 padding-2">
            <h5 style={{ color: "#1F2D41" }}>
              <b>CONTACTO</b>
            </h5>
            <ul style={{ color: "#1F2D41" }}>
              <li>
                <p>
                  <b>
                    3338490461 <br /> 3315855194
                  </b>
                </p>
              </li>
              <li>
                <p>
                  <b>flores.juan@jucaba.mx</b>
                </p>
              </li>
              <li>
                <p>
                  <b>
                    Av. Providencia 2571, Providencia 1º Sección, CP. 44630
                    Guadalajara, Jalisco.
                  </b>
                </p>
              </li>
            </ul>
          </div>
          <div className="col s12 m6 l2 offset-m3">
            <ul className="margen-1">
              <li className="centrar-flex" style={{ margin: "5px 0" }}>
                <a className="alineacion-footer" href="/">
                  <b>Inicio</b>
                </a>
              </li>
              <li className="centrar-flex" style={{ margin: "5px 0" }}>
                <a className="alineacion-footer" href="/somos-jucaba">
                  Somos
                </a>
              </li>
              <li className="centrar-flex" style={{ margin: "5px 0" }}>
                <a className="alineacion-footer" href="/proyectos-jucaba">
                  Proyectos
                </a>
              </li>
              <li className="centrar-flex" style={{ margin: "5px 0" }}>
                <a className="alineacion-footer" href="/servicios-jucaba">
                  Servicios
                </a>
              </li>
              <li className="centrar-flex" style={{ margin: "5px 0" }}>
                <a className="alineacion-footer" href="/contactanos">
                  Contacto
                </a>
              </li>
              <li className="centrar-flex" style={{ margin: "5px 0" }}>
                <a className="alineacion-footer" href="/terminos-y-condiciones">
                  Privacidad / Términos
                </a>
              </li>
            </ul>
          </div>
          <div className="col s12 m6 l4 offset-m3">
            <h5 style={{ color: "#1F2D41" }}>
              <b>PORTAFOLIO</b>
            </h5>
            <div className="galeria-2">
              {galeria.map((imagen) => (
                <img
                  alt={imagen.titulo}
                  src={imagen.imagen}
                  style={{ width: "100%" }}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <div className="container center" style={{ color: "#1F2D41" }}>
          <p>
            © {new Date().getFullYear()} All Rights Reserved Desarrollos
            inmobiliarios JUCABA S. A. de C. V.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
