import React, { Fragment, useEffect } from "react";
import M from "materialize-css";

import img8 from "../Images/8.png";
import img8_1 from "../Images/8-1.png";

import Contacto from "../Components/Contacto";
import Proyectos from "../Components/Proyectos";

// galeria
import galeria1 from "../Images/galeria/1.png";
import galeria2 from "../Images/galeria/2.png";
import galeria3 from "../Images/galeria/3.png";
import galeria4 from "../Images/galeria/4.png";
import galeria5 from "../Images/galeria/5.png";
import galeria6 from "../Images/galeria/6.png";
import galeria7 from "../Images/galeria/7.png";
import galeria8 from "../Images/galeria/8.png";

const Portafolio = () => {
  const galeria = [
    {
      imagen: galeria1,
      titulo: "Imagen galeria JUCABA 1",
    },
    {
      imagen: galeria2,
      titulo: "Imagen galeria JUCABA 2",
    },
    {
      imagen: galeria3,
      titulo: "Imagen galeria JUCABA 3",
    },
    {
      imagen: galeria4,
      titulo: "Imagen galeria JUCABA 4",
    },
    {
      imagen: galeria5,
      titulo: "Imagen galeria JUCABA 5",
    },
    {
      imagen: galeria6,
      titulo: "Imagen galeria JUCABA 6",
    },
    {
      imagen: galeria7,
      titulo: "Imagen galeria JUCABA 7",
    },
    {
      imagen: galeria8,
      titulo: "Imagen galeria JUCABA 8",
    },
  ];

  const timeline = [
    {
      fecha: "2005 - 2006",
      informacion: "Adquisición y remodelación de oficinas actuales",
      margin: true,
    },
    {
      fecha: "2007 - 2008",
      informacion:
        "Urbanización y comercialización del Fraccionamiento Industrial Colli-Guadalupe",
      margin: true,
    },
    {
      fecha: "2009",
      informacion:
        "Edificación de 9 bodegas boutique de 250m2 en Parque Industrial y Comercial Guadalupe",
    },
    {
      fecha: "2010",
      informacion:
        "“Artgraph” Proyecto y edificación de bodega en calle Industria Vinícola",
    },
    {
      fecha: "2011",
      informacion:
        "“Ricoh” Proyecto y edificación de bodega en calle Industria Naviera 200",
    },
    {
      fecha: "2012",
      informacion:
        "“Plaza Sarabia” Proyecto y edificación de 40 locales comerciales",
    },
    {
      fecha: "2013",
      informacion: "“Jardines Universidad” Remodelación de residencia",
    },
    {
      fecha: "2014",
      informacion:
        "“Rinconada San Pedro” Proyecto, consultoría y trámites de desarrollo habitacional en Lagos De Moreno, Jalisco que consta de 52 lotes habitacionales",
    },
    {
      fecha: "2015",
      informacion:
        "Investigación y desarrollo de nuevas técnicas constructivas con contenedores marítimos de carga (Hong Kong, Pollo Pepe)",
    },
    {
      fecha: "2016",
      informacion:
        "“Container Center” Concurso Tec. De Monterrey de evaluación y análisis de viabilidad de realización de centro comercial con sistema constructivo a base contenedores marítimos de carga.",
    },
    {
      fecha: "2017",
      informacion: "Remodelación de “Casa Vidrio” en Colonia Americana",
    },
    {
      fecha: "2018",
      informacion: "Remodelación de “Casa Juan De Ojeda” en Colonia Americana",
    },
    {
      fecha: "2019",
      informacion:
        "Asesoría, consultoría y trámites permisos para “Parque Industrial Boutique” en Leon, Guanajuato",
    },
    {
      fecha: "2020",
      informacion:
        "Proyecto y edificación residencial “Cajititlán” En Tlajomulco De Zuñiga.",
    },
    {
      fecha: "2021",
      informacion:
        "Remodelación y adecuaciones de “Oficinas Coworking Periférico” en Zapopan, Jalisco",
    },
    {
      fecha: "2022",
      informacion:
        "Anteproyecto y análisis edificio de departamentos “General San Martín” ",
    },
    {
      fecha: "2023",
      informacion: "Proyecto “Bodega Periferico” ",
    },
    {
      fecha: "2024",
      informacion:
        "Proyecto ecoturístico, análisis viabilidad, trámites “Ferrería de Tula”",
    },
  ];

  useEffect(() => {
    var elems = document.querySelectorAll(".materialboxed");
    M.Materialbox.init(elems);
  }, []);

  return (
    <Fragment>
      {window.screen.width <= 992 ? (
        <div className="imagne-principal">
          <img src={img8_1} alt="Somos JUCABA" style={{ width: "100%" }} />
          <div>
            <p>
              <span className="titulo-3">Nuestros</span> <br />
              <span className="titulo-4">PROYECTOS</span>
            </p>
          </div>
        </div>
      ) : (
        <div className="imagne-principal">
          <img src={img8} alt="Somos JUCABA" style={{ width: "100%" }} />
          <div>
            <p>
              <span className="titulo-3">Nuestros</span> <br />
              <span className="titulo-4">PROYECTOS</span>
            </p>
          </div>
        </div>
      )}

      <section className="container center" style={{ marginTop: "50px" }}>
        <Proyectos />
      </section>

      <section className="container" style={{ marginTop: "50px" }}>
        <div class="comment">
          {timeline.map((date) =>
            date.margin ? (
              <p data-text={date.fecha} className="margin-timeline">
                {date.informacion}
              </p>
            ) : (
              <p data-text={date.fecha}>{date.informacion}</p>
            )
          )}
        </div>
      </section>

      <section
        className="container center galeria"
        style={{ marginTop: "50px" }}
      >
        {galeria.map((imagen) => (
          <img
            className="materialboxed"
            alt={imagen.titulo}
            src={imagen.imagen}
            style={{ width: "100%" }}
          />
        ))}
      </section>

      <Contacto />
    </Fragment>
  );
};

export default Portafolio;
