import React from "react";

// imagenes
import img4 from "../Images/4.png";
import img5 from "../Images/5.png";
import img6 from "../Images/6.png";

const Proyectos = () => {
  return (
    <div className="row">
      <div className="col s12 m4 l4">
        <a href="/proyectos-jucaba">
          <div className="proyectos-crad">
            <img src={img4} alt="" />
            <div style={{ background: "#1F2D41" }}>
              <p>Plaza Sarabia</p>
            </div>
          </div>
        </a>
      </div>
      <div className="col s12 m4 l4">
        <a href="/proyectos-jucaba">
          <div className="proyectos-crad">
            <img src={img5} alt="" />
            <div style={{ background: "#008DAB" }}>
              <p>
                Parque Industrial y <br />
                Comercial Guadalupe
              </p>
            </div>
          </div>
        </a>
      </div>
      <div className="col s12 m4 l4">
        <a href="/proyectos-jucaba">
          <div className="proyectos-crad">
            <img src={img6} alt="" />
            <div style={{ background: "#1F2D41" }}>
              <p>
                Edificio en proyecto <br />
                Torre general San Martín
              </p>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default Proyectos;
