const firebaseHelper = {
    /**
     * 
     * @param {nombre de la coleccion a eliminar} collection 
     * @param {conexion de firebase} firestore 
     * @param {datos a agregar} data 
     * @param {URL de redireccionamiento} url 
     * @param {conexion con funcion history} history 
     */
    agregar: (collection, firestore, data, url) => {
        firestore.add({ 
            collection: collection 
        }, data).then(() => {
            window.location.replace(url);
        });
    }
}

export default firebaseHelper;