import React, { Fragment } from "react";

import video1 from "../Video/principal_g.mp4";
import video2 from "../Video/principal_m.mp4";

// icons
import icon1 from "../Images/1.svg";
import icon2 from "../Images/2.svg";
import icon3 from "../Images/3.svg";
import icon4 from "../Images/4.svg";
import icon5 from "../Images/5.svg";
import icon6 from "../Images/6.svg";

// imagenes
import img1 from "../Images/1.png";
import img2 from "../Images/2.png";
import img3 from "../Images/3.png";

// clientes imagenes
import cliente1 from "../Images/clientes/1.png";
import cliente2 from "../Images/clientes/2.png";
import cliente3 from "../Images/clientes/3.png";
import cliente4 from "../Images/clientes/4.png";
import cliente5 from "../Images/clientes/5.png";

import Contacto from "../Components/Contacto";
import Proyectos from "../Components/Proyectos";

const Index = () => {
  return (
    <Fragment>
      {window.screen.width <= 992 ? (
        <video
          class="responsive-video"
          controls={false}
          muted={true}
          autoPlay={true}
          loop={true}
        >
          <source src={video2} type="video/mp4" />
        </video>
      ) : (
        <video
          class="responsive-video"
          controls={false}
          muted={true}
          autoPlay={true}
          loop={true}
        >
          <source src={video1} type="video/mp4" />
        </video>
      )}

      <section className="container center" style={{ marginTop: "50px" }}>
        <p className="titulo-1">
          Desarrollos Inmobiliarios JUCABA S. A. De C. V.
        </p>
        <p>
          Nos especializamos en la Adquisición, Remodelación y Creación de{" "}
          <br className="invisible visible_g visible_mg visible_m" />
          activos patrimoniales inmobiliarios estratégicos; que ofrecen a{" "}
          <br className="invisible visible_g visible_mg visible_m" />
          nuestros clientes, inversionistas y accionistas la máxima{" "}
          <br className="invisible visible_g visible_mg visible_m" />{" "}
          rentabilidad sobre su inversión.
        </p>

        <div className="row" style={{ marginTop: "50px" }}>
          <div className="col s12 m12 l4">
            <div
              style={{
                border: "solid 1px",
                borderRadius: "50%",
                width: "73px",
                height: "73px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
              }}
            >
              <img style={{ width: "45px" }} src={icon1} alt=" Experiencia" />
            </div>
            <p className="titulo-2">Experiencia</p>
            <p style={{ color: "#6F6F6F" }}>
              Desde 2005 evolucionamos nuestro modelo{" "}
              <br className="invisible visible_g visible_m visible_c" /> de
              negocio construyendo proyectos con{" "}
              <br className="invisible visible_g visible_m visible_c" />{" "}
              oportunidades y altamente competitivos.
            </p>
          </div>
          <div className="col s12 m12 l4">
            <div
              style={{
                borderRadius: "50%",
                width: "73px",
                height: "73px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
                background: "#0084C4",
              }}
            >
              <img
                style={{ width: "45px" }}
                src={icon2}
                alt="SERVICIO PERSONALIZADO"
              />
            </div>
            <p className="titulo-2" style={{ color: "#0084C4" }}>
              SERVICIO PERSONALIZADO
            </p>
            <p style={{ color: "#6F6F6F" }}>
              Nos adaptamos a tus metas,{" "}
              <br className="invisible visible_g visible_m visible_c" />{" "}
              materializándolas en proyectos;{" "}
              <br className="invisible visible_mg" /> ya sea como{" "}
              <br className="invisible visible_g visible_m visible_c" />{" "}
              propietario o inversionista de proyectos.
            </p>
          </div>
          <div className="col s12 m12 l4">
            <div
              style={{
                border: "solid 1px",
                borderRadius: "50%",
                width: "73px",
                height: "73px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
              }}
            >
              <img
                style={{ width: "45px" }}
                src={icon3}
                alt="Inversión Sólida"
              />
            </div>
            <p className="titulo-2">Inversión Sólida</p>
            <p style={{ color: "#6F6F6F" }}>
              Ofrecemos a clientes y a socios inversionistas{" "}
              <br className="invisible visible_g visible_m visible_c" /> la
              oportunidad de construir patrimonios{" "}
              <br className="invisible visible_g visible_m visible_c" /> con un
              crecimiento constante.
            </p>
          </div>
        </div>
      </section>

      <section className="container" style={{ marginTop: "50px" }}>
        <p className="titulo-1">Nuestra experiencia</p>
        <div className="row center">
          <div className="col s12 m12 l4">
            <img
              src={icon4}
              style={{ width: "55px" }}
              alt="Metros urbanizados"
            />
            <p className="contador" style={{ color: "#046DA0" }}>
              103,000 m2
            </p>
            <p className="texto-pequeño" style={{ color: "#046DA0" }}>
              Metros urbanizados
            </p>
          </div>
          <div className="col s12 m12 l4">
            <img
              src={icon5}
              style={{ width: "55px" }}
              alt="Metros Remodelados"
            />
            <p className="contador">3,000 m2</p>
            <p className="texto-pequeño">Remodelados</p>
          </div>
          <div className="col s12 m12 l4">
            <img
              src={icon6}
              style={{ width: "55px" }}
              alt="Metros Construidos"
            />
            <p className="contador" style={{ color: "#046DA0" }}>
              15,000 m2
            </p>
            <p className="texto-pequeño" style={{ color: "#046DA0" }}>
              Construidos
            </p>
          </div>
        </div>
      </section>

      <section className="container" style={{ marginTop: "50px" }}>
        <p className="titulo-1">Servicios</p>
        <div className="row center">
          <div className="col s12 m4 l4">
            <a href="/servicios-jucaba">
              <div className="servicios-card">
                <div style={{ background: "#008cab80" }}>
                  <p className="titulo-sn" style={{ color: "#fff" }}>
                    Consultoría <br />
                    inmobiliaria.
                  </p>
                </div>
                <img
                  src={img1}
                  alt="Consultoría inmobiliaria"
                  style={{ width: "100%" }}
                />
              </div>
            </a>
          </div>
          <div className="col s12 m4 l4">
            <a href="/servicios-jucaba">
              <div className="servicios-card">
                <div style={{ background: "#0083c488" }}>
                  <p className="titulo-sn" style={{ color: "#fff" }}>
                    Proyecto <br />
                    arquitectónico.
                  </p>
                </div>
                <img
                  src={img2}
                  alt="Proyecto arquitectónico"
                  style={{ width: "100%" }}
                />
              </div>
            </a>
          </div>
          <div className="col s12 m4 l4">
            <a href="/servicios-jucaba">
              <div className="servicios-card">
                <div style={{ background: "#1f2d4171" }}>
                  <p className="titulo-sn" style={{ color: "#fff" }}>
                    Ejecución <br />
                    de obra.
                  </p>
                </div>
                <img
                  src={img3}
                  alt="Ejecución de obra"
                  style={{ width: "100%" }}
                />
              </div>
            </a>
          </div>
        </div>
        <div className="centrar-flex">
          <a className="boton-2" href="/servicios-jucaba">
            Ver servicios
          </a>
        </div>
      </section>

      <section className="container center" style={{ marginTop: "50px" }}>
        <p className="titulo-1">Proyectos</p>
        <Proyectos />
        <div className="centrar-flex">
          <a className="boton-2" href="/proyectos-jucaba">
            Ver más proyectos
          </a>
        </div>
      </section>

      <section className="container center" style={{ marginTop: "50px" }}>
        <p className="titulo-1">
          <span>
            Algunos de nuestros <br className="invisible visible_c" />
            <span style={{ color: "#BE4F00" }}>clientes satisfechos</span>
          </span>
        </p>

        <div
          className="row container"
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px 0",
          }}
        >
          <div className="col s12 m4 l4">
            <img src={cliente1} alt="FESTO" />
          </div>
          <div className="col s12 m4 l4">
            <img src={cliente2} alt="TIBAIT" />
          </div>
          <div className="col s12 m4 l4">
            <img src={cliente3} alt="RICOH" />
          </div>
          <div className="col s12 m4 l4 offset-m2 offset-l2">
            <img src={cliente4} alt="Atlas Copco" />
          </div>
          <div className="col s12 m4 l4" style={{ margin: "auto" }}>
            <img src={cliente5} alt="Nova Foods" />
          </div>
        </div>
      </section>

      <Contacto />
    </Fragment>
  );
};

export default Index;
