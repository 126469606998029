import React, { Fragment } from "react";

import img10 from "../Images/10.png";
import img10_1 from "../Images/10-1.png";

import ContactoCP from "../Components/Contacto";
const Contacto = () => {
  return (
    <Fragment>
      {window.screen.width <= 992 ? (
        <div className="imagne-principal">
          <img src={img10_1} alt="Somos JUCABA" style={{ width: "100%" }} />
          <div>
            <p>
              <span className="titulo-4">Contacto</span>
            </p>
          </div>
        </div>
      ) : (
        <div className="imagne-principal">
          <img src={img10} alt="Somos JUCABA" style={{ width: "100%" }} />
          <div style={{ background: "#1f2d4171" }}>
            <p>
              <span className="titulo-4">Contacto</span>
            </p>
          </div>
        </div>
      )}

      <ContactoCP />
    </Fragment>
  );
};

export default Contacto;
