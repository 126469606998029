import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// *** componentes ***  //
import Header from "../Components/Header";
import Footer from "../Components/Footer";

// *** vistas ***  //
import Index from "../Views/Index";
import Nosotros from "../Views/Nosotros";
import Servicios from "../Views/Servicios";
import Portafolio from "../Views/Portafolio";
import Contacto from "../Views/Contacto";
import Mensaje from "../Views/Mensaje";
import Terminos from "../Views/Terminos";
import Error from "../Views/Error";

const Routers = () => (
  <BrowserRouter>
    <Header />

    <Routes>
      {/* vistas */}
      <Route exact path="/" element={<Index />} />
      <Route path="/somos-jucaba" element={<Nosotros />} />
      <Route path="/servicios-jucaba" element={<Servicios />} />
      <Route path="/proyectos-jucaba" element={<Portafolio />} />
      <Route path="/contactanos" element={<Contacto />} />
      <Route path="/envio-exitoso" element={<Mensaje />} />
      <Route path="/terminos-y-condiciones" element={<Terminos />} />
      <Route path="/mensaje-enviado" element={<Terminos />} />

      <Route path="/*" element={<Error />} />
    </Routes>

    <Footer />
  </BrowserRouter>
);

export default Routers;
