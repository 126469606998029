import React from "react";

import gracias from "../Images/gracias.svg";

const Mensaje = () => {
  return (
    <section
      className="container row centrar-flex center"
      style={{
        minHeight: "60vh",
        flexWrap: "wrap",
      }}
    >
      <div className="col s12 m12 l6">
        <p className="texto-error-1" style={{ fontSize: "25px" }}>
          <b>Gracias por enviar tus datos,</b>{" "}
        </p>
        <p className="texto-error-1">nos pondremos en contacto pronto.</p>
        <p className="texto-error-1" style={{ marginTop: "30px" }}>
          Puedes regresar al{" "}
          <a style={{ color: "#BE4F00", fontWeight: "bold" }} href="/">
            inicio
          </a>
          , o ver nuestros{" "}
          <a
            style={{ color: "#008DAB", fontWeight: "bold" }}
            href="/proyectos-jucaba"
          >
            proyectos
          </a>
          .
        </p>
      </div>
      <div className="col s12 m12 l6">
        <img
          src={gracias}
          alt="Gracias por enviarnos tu mensaje"
          style={{ width: "100%" }}
        />
      </div>
    </section>
  );
};

export default Mensaje;
