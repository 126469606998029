import React, { Fragment } from "react";

import img9 from "../Images/9.png";
import img9_1 from "../Images/9-1.png";

import Contacto from "../Components/Contacto";

const Servicios = () => {
  return (
    <Fragment>
      {window.screen.width <= 992 ? (
        <div className="imagne-principal">
          <img src={img9_1} alt="Somos JUCABA" style={{ width: "100%" }} />
          <div>
            <p>
              <span className="titulo-3">Nuestros</span> <br />
              <span className="titulo-4">Servicios</span>
            </p>
          </div>
        </div>
      ) : (
        <div className="imagne-principal">
          <img src={img9} alt="Somos JUCABA" style={{ width: "100%" }} />
          <div>
            <p>
              <span className="titulo-3">Nuestros</span> <br />
              <span className="titulo-4">Servicios</span>
            </p>
          </div>
        </div>
      )}

      <section className="container center">
        <div>
          <p className="titulo-1">Proyecto arquitectónico</p>
          <p>
            Consultoría{" "}
            <b>legal, contable y fiscal, comercial, promoción, construcción</b>{" "}
            de proyectos <br /> inmobiliarios buscando una excelente ubicación,
            plusvalía, rendimiento. <br /> A través de arquitectos reconocidos,
            proyectos bien pensados, buscando siempre la mejora <br /> continua
            e innovación así como y las mejores tendencias o técnicas
            constructivas.
          </p>
        </div>

        <div style={{ margin: "70px 0" }}>
          <p className="titulo-1">
            Consultoría inmobiliaria <br className="invisible visible_c" /> en
            general
          </p>
          <p>
            Ofrecemos una forma práctica y cómoda de asegurar no sólo que toda
            vivienda{" "}
            <b>
              tiene todos <br /> los permisos en regla,
            </b>{" "}
            sino otros temas como{" "}
            <b>
              anteproyecto, modelos BIM, proyecto <br /> ejecutivo, estudio de
              viabilidad, etc.
            </b>
          </p>
        </div>

        <div>
          <p className="titulo-1">Ejecución de obra</p>
          <p>
            Realizamos el conjunto de las fases sucesivas necesarias para,
            llevar a cabo la realización de <br /> una edificación, construcción
            o cualquier otro tipo de obra con las siguientes actividades:
          </p>
          <p
            style={{
              textAlign: "left",
              display: "flex",
              justifyContent: " center",
            }}
          >
            · Interpretación y adaptación del proyecto a las vicisitudes de la
            obra. <br />
            · Coordinación técnica entre instaladores, contratistas,
            suministradores, etc. <br />
            · Preparación de detalles de obra, pasos, etc. <br />
            · Revisión y aprobación de planos de montaje. <br />
            · Revisión y aprobación de replanteos. <br />
            · Ejecución de cada una de las actividades que forman parte de la
            obra. <br />
            · Control de ejecución. Revisión planos final de obra, normas de
            funcionamiento y mantenimiento. <br />
            · Preparación recepción de la obra. <br />
            · Control de mediciones. <br />· Apoyo a las gestiones para la
            obtención de permisos oficiales. <br />· Control e impulsión de la
            gestión con compañías suministradoras de fluidos y energía.
          </p>
        </div>
      </section>

      <Contacto />
    </Fragment>
  );
};

export default Servicios;
